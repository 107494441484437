import React from "react";
import { graphql } from "gatsby";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import BreadcrumbList from "../../components/seo/BreadcrumbList";

const Einlagensicherung = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader h1Title="Einlagensicherung – was ist das?" showCalc={false} />
            <Article>
                <p>
                    Als zukünftige Kreditnehmer:in fragst du dich möglicherweise, was mit deinem Ersparten passiert,
                    falls deine Bank insolvent ist. Um zu verhindern, dass Anleger:innen ihr gesamtes Vermögen
                    verlieren, gibt es in Österreich und anderen EU-Staaten die sogenannte Einlagensicherung. Was das
                    ist und wie es dich und dein Kapital im Ernstfall schützt, erfährst du im folgenden Beitrag.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Was ist eine Einlagensicherung?</h2>
                <p>
                    Die Einlagensicherung dient dem Schutz von Vermögenswerten. Nicht nur Privatpersonen oder
                    Unternehmen, sondern auch Banken können im{" "}
                    <Link to="/artikel/immobilienblase/" target="_blank" rel="noreferrer noopener">
                        Krisenfall
                    </Link>{" "}
                    zahlungsunfähig werden. Damit in einem solchen Fall nicht weite Teile der Bevölkerung ihr Erspartes
                    verlieren, was die Stabilität des Finanzmarkt erheblich gefährden würde, wurde 2015 in Österreich
                    auf Grundlage einer EU-Richtlinie das{" "}
                    <a
                        href="https://www.ris.bka.gv.at/GeltendeFassung.wxe?Abfrage=Bundesnormen&Gesetzesnummer=20009251"
                        rel="noreferrer noopener"
                        target="_blank"
                    >
                        Einlagensicherungs- und Anlegerentschädigungsgesetz
                    </a>{" "}
                    (kurz ESAEG) verabschiedet.
                </p>
                <hr />

                <h2>Was umfasst die Einlagensicherung?</h2>
                <p>
                    Das ESAEG bezieht sich auf die Einlagen sowohl natürlicher als auch juristischer Personen bei
                    sämtlichen in Österreich zugelassenen Banken. Die Staatsbürgerschaft der Anleger:in ist dabei nicht
                    relevant. Die Einlagensicherung umfasst unter anderem Girokonten, Gehalts- und Pensionskonten,
                    Sparbücher und Bausparverträge mit einem Betrag von bis zu 100 000 Euro pro Bank und Person. Nicht
                    unter die österreichische Einlagensicherung fallen jedoch Einlagen bei Zweigstellen von Banken, die
                    in anderen EU-Staaten situiert sind. Hier greift die Einlagensicherung des Landes, aus dem die
                    betreffende Bank stammt.
                </p>
                <hr />

                <h2>Wie lange bis zur Auszahlung?</h2>
                <p>
                    Im Fall der Fälle greift die Einlagensicherung rasch: 20 Arbeitstage dauert es bis zur
                    Rückerstattung deines Guthabens maximal. Diese Frist wird dem ESAEG gemäß allerdings sukzessive
                    reduziert, sodass du ab 2024 nur noch höchstens 7 Tage warten musst, bis du und dein Geld wieder
                    vereint seid. Einen Antrag musst du nicht stellen, lediglich das Konto, auf das der Betrag
                    transferiert werden soll ist anzugeben. Falls du Fragen zur Einlagensicherung hast, kannst du auch
                    eine unserer Finanzierungsexpert:innen kontaktieren. Wir helfen dir bei allen Fragen rund um das
                    Thema Wohnfinanzierung: Ob{" "}
                    <Link to="/artikel/tilgung/" target="_blank" rel="noreferrer noopener">
                        Tilgung
                    </Link>
                    ,{" "}
                    <Link to="/artikel/schulden/" target="_blank" rel="noreferrer noopener">
                        Schulden
                    </Link>
                    ,{" "}
                    <Link to="/artikel/sanierung/" target="_blank" rel="noreferrer noopener">
                        Sanierung
                    </Link>{" "}
                    oder der unverbindliche{" "}
                    <Link to="/dienstleistungen/kreditrechner/" target="_blank" rel="noreferrer noopener">
                        Kreditrechner
                    </Link>{" "}
                    - wir haben alle Informationen, die du brauchst.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
            </Article>

            <BreadcrumbList page={"einlagensicherung"}></BreadcrumbList>
            <ArticleStructuredData page={"einlagensicherung"} heading={"Einlagensicherung – was ist das?"} />
        </Layout>
    );
};

export default Einlagensicherung;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["header", "footer", "page.artikel.einlagensicherung", "mortgageCalc"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
